@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

.gradient-circle {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 70%);
}

.gradient-circle-blue {
    background: radial-gradient(circle, rgba(0, 0, 255, 0.9), rgba(0, 0, 255, 0.5), rgba(135, 206, 235, 0) 70%);
}

.gradient-circle-purple {
    background: radial-gradient(circle, rgba(128, 0, 128, 0.9), rgba(128, 0, 128, 0.5), rgba(238, 130, 238, 0) 70%);
}

.gradient-circle-orange {
    background: radial-gradient(circle, rgba(255, 165, 0, 0.9), rgba(255, 165, 0, 0.5), rgba(255, 140, 0, 0) 70%);
}

.gradient-circle-yellow {
    background: radial-gradient(circle, rgba(255, 255, 0, 0.9), rgba(255, 255, 0, 0.5), rgba(255, 255, 224, 0) 70%);
}